import { DateTime } from 'luxon'

const weekNumberLookup = [];
let lastWeek = 0;
let weekIndex = 0;
for (let i = 1; i <= 53; ++i) {
    const dateObj = DateTime.fromObject({weekNumber: i});
    if (lastWeek !== dateObj.month) {
        weekIndex = 1;
        lastWeek = dateObj.month;
    }

    if (!weekNumberLookup[dateObj.month]) {
        weekNumberLookup[dateObj.month] = [];
    }
    weekNumberLookup[dateObj.month][weekIndex] = i;
    weekIndex++;
}

$(() => {

    const $month = app.DOM.form_input.filter('select#first_reference_period_month');
    const $week = app.DOM.form_input.filter('select#first_reference_period_week');
    const $length = app.DOM.form_input.filter('select#reference_period_length');
    const $rolling = app.DOM.form_input.filter('#reference_period_rolling_yes, #reference_period_rolling_no');
    const $rollingYes = $rolling.filter('#reference_period_rolling_yes');

    app.DOM.form_input.filter('select#first_reference_period_week,select#first_reference_period_month,select#reference_period_length').change( e => {

        const dates = calculate_dates_from_week_and_month(
            $week.val(),
            $month.val(),
            $length.val(),
        );

        app.DOM.form.find('td#ref_1').text(dates[0]);
        app.DOM.form.find('td#ref_2').text(dates.length === 1 ? 'N/A' : dates[1]);
        app.DOM.form.find('td#ref_3').text(dates.length === 2 ? 'N/A' : dates[2]);
    });

    $rolling.on('change', e => {

        if( $rollingYes.is(':checked') ){
            $week.addClass('disabled').prop('disabled', true);
            $month.addClass('disabled').prop('disabled', true);
        } else {
            $week.removeClass('disabled').prop('disabled', false);
            $month.removeClass('disabled').prop('disabled', false)
        }
    }).eq(0).trigger('change');

    app.DOM.form_input.filter('select[id$=reference_period_week]').trigger('change');
});

function calculate_dates_from_week_and_month(week, month, referenceDuration)
{
    const refs = [];
    let initial = DateTime.fromObject({
        weekNumber: Number.parseInt(weekNumberLookup[month][week]),
        weekday: 1
    })
    if (initial > DateTime.now()) {
        initial = DateTime.fromObject({
            weekYear: initial.weekYear - 1,
            weekNumber: Number.parseInt(weekNumberLookup[month][week]),
            weekday: 1
        })
    }
    refs.push(initial);

    if (referenceDuration < 52) {
        refs.push(refs[0].plus({ weeks: referenceDuration }))

        if (referenceDuration < 26) {
            refs.push(refs[0].plus({ weeks: referenceDuration * 2 }))
        }
    }

    refs.push(DateTime.fromObject({
        weekYear: initial.weekYear + 1,
        weekNumber: Number.parseInt(weekNumberLookup[month][week]),
        weekday: 1
    }))

    return refs.map((ref1, index, array) => {
        if (index === array.length - 1) {
            return null;
        }
        const ref2 = array[index + 1];
        return `${ref1.toLocaleString(DateTime.DATE_MED)} - ${ref2.minus({ days: 1 }).toLocaleString(DateTime.DATE_MED)}`
    }).filter((v) => v !== null);
}